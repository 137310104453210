import React from 'react';

function Test() {
  return (
    <div>
      <h1 style={{color: 'black'}}>Additional details forthcoming.</h1>
      <c>I am working on it :)</c>
    </div>
  );
}

export default Test;

